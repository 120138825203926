import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FirestoreCollection } from 'react-firestore';

import PhotoSelect from "./PhotoSelect";
import FoodEdit from "./FoodEdit";
import CheckDlg from "./CheckDlg";
import TitleEdit from "./TitleEdit";
import TitleGet from "./TitleGet";

import FoodActionsIcon from '@material-ui/icons/PermMediaSharp';
import FoodActionsDlg from './FoodActions/FoodActionsDlg';

import './index.css';

/////////////////////////////////////////////////////////////////////////////////////////////////////////
class DataTable extends React.Component{
  
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isPhoto: false,
      isTitle: false,
      isVolume: false,
      isCount: false,
      isBase: false,
      isProgram: false,
      data: null,
    };
    this.openModal =  this.openModal.bind(this);
  }
  // openModal1 = (event) => this.setState({isOpen: true, data: event.target.dataset });
  openModal = (data) => this.setState({isOpen: true, data: data });
  closeModal = () => this.setState({ isOpen: false, data: null });

  openVolume = (data) => this.setState({isVolume: true, data: data });
  closeVolume = () => this.setState({ isVolume: false, data: null });

  openCount = (data) => this.setState({isCount: true, data: data });
  closeCount = () => this.setState({ isCount: false, data: null });

  openBase = (data) => this.setState({isBase: true, data: data });
  closeBase = () => this.setState({ isBase: false, data: null });

  openPhoto = (data) => this.setState({isPhoto: true, data: data });
  closePhoto = () => this.setState({ isPhoto: false, data: null });

  openTitle = (data) => this.setState({isTitle: true, data: data });
  closeTitle = () => this.setState({ isTitle: false, data: null });

  openFoodActions = (data) => this.setState({isProgram: true, data: data});
  closeFoodActions = () => this.setState({isProgram: false, data: null});

  render(){

    const {classes, dataArr, lang } =  this.props;

    const photoId = this.state.data
                    ? (this.state.data.id? this.state.data.id:'')
                    :'';
    const photoUrl = this.state.data
                  ? (this.state.data.urlM? this.state.data.urlM:'')
                  :'';

    return(
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{width:'150'}} className={classes.cellpointer} onClick={()=> this.openModal(null)}>
                New Food</TableCell>
              <TableCell>Photo</TableCell>
              <TableCell>Hint</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>isLiter</TableCell>
              <TableCell>isCount</TableCell>
              <TableCell>isBase</TableCell>
              <TableCell>weight</TableCell>
              <TableCell>length</TableCell>
              <TableCell>FoodActions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataArr.map(data =>
              <TableRow key={data.id}>
                <TableCell component="th" scope="row" className={classes.cellpointer} onClick={() => this.openModal(data)}>
                {/* <TableCell component="th" scope="row" className={classes.cellpointer} data-param={data} onClick={this.openModal1}> */}
                  {data.id}</TableCell>
                <TableCell style={{padding:'0 2px'}}>{ data.urlM
                  ? <img src={data.urlM} alt='icon' style={{height:'56px',display:'flex',cursor:'pointer'}} onClick={() => this.openPhoto(data)}/>
                  : <button onClick={() => this.openPhoto(data)}> Photo </button> }
                </TableCell>
                <TableCell>{data.hint}</TableCell>
                <TableCell className={classes.cellpointer} onClick={()=> this.openTitle(data)}>
                  <TitleGet store="food" lang={lang} docId={data.id}/>
                </TableCell>
                <TableCell numeric>{data.size}</TableCell>
                <TableCell className={classes.cellpointer} onClick={()=> this.openVolume(data)}>{data.isLiter ? 'Y': '_'}</TableCell>
                <TableCell className={classes.cellpointer} onClick={()=> this.openCount(data)}>{data.isCount ? 'Y' : '_'}</TableCell>
                <TableCell className={classes.cellpointer} onClick={()=> this.openBase(data)}>{data.isBase ? 'Y' : '_'}</TableCell>
                <TableCell numeric>{data.weight}</TableCell>
                <TableCell numeric>{data.length}</TableCell>
                <TableCell className={classes.cellpointer} onClick={()=> this.openFoodActions(data)}><FoodActionsIcon/></TableCell>
            </TableRow>
          )}
          </TableBody>
        </Table>
        <CheckDlg show={this.state.isVolume}
          field={'isLiter'}
          handleClose={this.closeVolume}
          data={this.state.data}
          isUse={this.state.data ? this.state.data.isLiter : false}
        />
        <CheckDlg show={this.state.isCount}
          field={'isCount'}
          handleClose={this.closeCount}
          data={this.state.data}
          isUse={this.state.data ? this.state.data.isCount : false}
        />
        <CheckDlg show={this.state.isBase}
          field={'isBase'}
          handleClose={this.closeBase}
          data={this.state.data}
          isUse={this.state.data ? this.state.data.isBase : false}
        />
        <FoodEdit show={this.state.isOpen}
          handleClose={this.closeModal}
          data={this.state.data}
        />
        <FoodActionsDlg show={this.state.isProgram}
          handleClose={this.closeFoodActions}
          foodId={photoId}
        />
        <PhotoSelect show={this.state.isPhoto}
          handleClose={this.closePhoto}
          docId={photoId}
          imageUrl={photoUrl}
          storeage={'food_image'}
          store={'foods'}
        />
        <TitleEdit
          show={this.state.isTitle}
          handleClose={this.closeTitle}
          docId={this.state.data ? this.state.data.id : ''}
          lang={lang}
          store={'food'}          
        />
      </Paper>
    );
  }
}

DataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  dataArr: PropTypes.array.isRequired,
};


/////////////////////////////////////////////////////////
const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    // marginTop:0
  },
  table: {
    minWidth: 100,
  },
  cellpointer:{
    cursor:'pointer',
    color: '#3443f6',
  },
});

const MyTable = withStyles(styles)(DataTable);

  const Foods = (props) =>
  <FirestoreCollection
    path="foods"
    sort="hint:asc"
    // limit={5}

    render={({ isLoading, data }) =>
      isLoading
      ? <p>Loding Data.</p>
      :<MyTable dataArr={data} lang={props.lang}/>
    }
  />

  export default Foods;
