import React from 'react';
import { firebase } from "../../firebase";
import FileUpload from "./FileUpload";
import PropTypes from "prop-types";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

class PhotoSelect extends React.PureComponent {
  constructor(props){
    super(props);

    this.state = {
      isProcessing: false
    }
    this.handleUploaded = this.handleUploaded.bind(this);
  }

  handleUploaded = (url) => {
    this.setState({
      isProcessing: true
    });
    const {
      docId,
      store,
    } = this.props;

    var docData = {
      urlM: url ? url : null,
      createdAt: firebase.FieldValue.serverTimestamp()
    };
    const db = firebase.firestore;
    const dataRef = db.collection(store).doc(docId);

    dataRef.update(docData)
    .then(()=>{
      this.setState({
        isProcessing: false
      });
      this.props.handleClose();
    })
    .catch( error => {
      this.setState({
        error:error,
        isProcessing: false
      });
    });
  }

  // render() ///////////////////////////////
  render() {

    const {
      show,
      docId,
      imageUrl,
      handleClose,
      storeage,
    } = this.props;

    if(!this.props.show || !this.props.docId) {
      return null;
    } 
    return (
      <Dialog
        open={show}
        onClose={handleClose}
        scroll='paper'
        // style={{height:'500px', minHeight:'500px'}}
        style={{maxHeight:'700px'}}
      >
        <DialogTitle id="form-dialog-title">:{storeage + '/'+ docId}</DialogTitle>
        <DialogContent>
          <FileUpload fileName={storeage + '/'+ docId} imageUrl={imageUrl} handleDone={this.handleUploaded} handleClose={handleClose}/>
        </DialogContent>
      </Dialog>
    );
  }
}

PhotoSelect.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  docId: PropTypes.string,
  imgUrl: PropTypes.string,
  storeage: PropTypes.string,
  store: PropTypes.string
}

export default PhotoSelect;
