import React from 'react';
import {firebase} from '../../../firebase';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import blue from "@material-ui/core/colors/blue";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end"
    },

    icon: {
        margin: theme.spacing(2)
    },

    iconHover: {
        margin: theme.spacing(2),
        "&:hover": {
            color: blue[800]
        }
    },

    button: {
        margin: theme.spacing(1)
    },

    input: {
        display: 'none',
    },
});

class CategoryEdit extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isUse: 0, 
            isManager: 0,
            isProcessing: false,
        }

        this.handleSave = this.handleSave.bind(this);
        this.updateInput = this.updateInput.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show) {
            const data = nextProps.data;
            const total = nextProps.total;

            if (data) {
                this.setState({
                    hint     : data.hint ? data.hint : '',
                    sortno   : data.sortno ? Number(data.sortno) : total,
                    isUse    : data.isUse ? Number(data.isUse) : 0,
                    isManager: data.isManager ? Number(data.isManager) : 0
                });
            } else {
                this.setState({
                    hint     : '',
                    sortno   : total,
                    isUse    : 1,
                    isManager: 0
                });
            }
        }
    }

    handleSave = (e) => {
        e.preventDefault();
        const {
            sortno,
            hint,
            isManager,
            isUse
        } = this.state;
        const {data, total} = this.props;

        var docData = {
            sortno   : sortno ? Number(sortno) : Number(total),
            hint     : hint ? hint : '',
            isManager: isManager ? Number(isManager) : 0,
            isUse    : isUse ? Number(isUse) : 0,
            createdAt: firebase.FieldValue.serverTimestamp()
        };

        const db = firebase.firestore;
        const categoryRef = db.collection('categories');

        if (data) {
            const docId = this.props.data.id;
            categoryRef.doc(docId).update(docData).then(() => {
                sortnoFromData(data, docData);
                this.props.handleClose();
            }).catch( error => {
                this.setState({
                    error: error,
                    isProcessing: false
                });
            });

        } else {    
            categoryRef.add(docData).then(doc => {
                sortnoFromIndex(doc.id, total, docData);
                this.props.handleClose();
            }).catch( error => {
                this.setState({
                    error: error,
                    isProcessing: false
                });
            });
        }
    }

    updateInput = (e) => {
        switch (e.target.type) {
        case 'number':
            this.setState({
                [e.target.id]: Number(e.target.value)
            });
            break;

        case 'checkbox':
            this.setState({ 
                [e.target.id]: e.target.checked
            });
            break;

        default:
            this.setState({
                [e.target.id]: e.target.value
            });
            break;
        }
    }

    render() {
        const {show, handleClose} = this.props;

        if (!show) {return null;}

        const {
            sortno,
            hint,
            isManager,
            isUse,
            isProcessing,
        } = this.state;

        const docId = this.props.data ? (this.props.data.id ? this.props.data.id : 'newID') : 'newID';
        const isInvalid = isProcessing;

        return (
            <Dialog
                open={show}
                onClose={handleClose}
                scroll='paper'
                style={{maxHeight: '700px'}}
            >
                <DialogTitle id='form-dialog-title'>ID_: {docId}</DialogTitle>
                <DialogContent>
                    <TextField
                        label='sortno'
                        id='sortno'
                        value={sortno}
                        type='number'
                        InputProps={{inputProps: {min: 1, max: 100}}}
                        onChange={this.updateInput}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <FormControlLabel 
                        control={<Checkbox id='isManager' checked={isManager == 1 ? true : false}/>} 
                        onChange={this.updateInput} 
                        label='isManager'
                    />
                    &nbsp;
                    <FormControlLabel 
                        control={<Checkbox id='isUse' checked={isUse == 1 ? true : false}/>}
                        onChange={this.updateInput} 
                        label='isUse'
                    />
                    <br/>
                    <TextField
                        label='hint'
                        id='hint'
                        value={hint}
                        onChange={this.updateInput}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='primary' className={styles.button} disabled={isInvalid} onClick={this.handleSave}>
                        Save
                    </Button>
                    <Button variant='contained' className={styles.button} onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

async function sortnoFromIndex(id, index, data) {
    const oldno = index ? Number(index) : 0;
    const newno = data.sortno ? Number(data.sortno) : 0;
    if (oldno > newno) {
        const db = firebase.firestore;
        const ref = db.collection('categories');

        const snapshot = await ref.where('sortno', '>=', newno).get();
        if (!snapshot.empty) {
            snapshot.forEach(doc => {
                const sortno = Number(doc.data().sortno);
                if (doc.id != id && (sortno < oldno)) {
                    const sort = {sortno: sortno + 1};
                    ref.doc(doc.id).update(sort).then(() => {

                    }).catch(error => {
                        console.log(error);
                    });
                }
            });
        }
    }
}

async function sortnoFromData(oldData, newData) {
    const oldno = oldData.sortno ? Number(oldData.sortno) : 0;
    const newno = newData.sortno ? Number(newData.sortno) : 0;
    if (oldno != newno) {
        const db = firebase.firestore;
        const ref = db.collection('categories');

        if (oldno > newno) { 
            const snapshot = await ref.where('sortno', '>=', newno).get();
            if (!snapshot.empty) {
                snapshot.forEach(doc => {
                    const sortno = Number(doc.data().sortno);
                    if (doc.id != oldData.id && (sortno < oldno && sortno >= newno)) {
                        const sort = {sortno: sortno + 1};
                        ref.doc(doc.id).update(sort).then(() => {

                        }).catch(error => {
                            console.log(error);
                        });
                    }
                });
            }
        } else { 
            const snapshot = await ref.where('sortno', '<=', newno).get();
            if (!snapshot.empty) {
                snapshot.forEach(doc => {
                    const sortno = Number(doc.data().sortno);
                    if (doc.id != oldData.id && (sortno > oldno && sortno <= newno)) {
                        const sort = {sortno: sortno - 1};
                        ref.doc(doc.id).update(sort).then(() => {

                        }).catch(error => {
                            console.log(error);
                        });
                    }
                });
            }
        }
    }
}

//for sortno switching function
async function sortnoSwichingFromData(oldData, newData) {
    const oldno = oldData.sortno ? Number(oldData.sortno) : 0;
    const newno = newData.sortno ? Number(newData.sortno) : 0;
    if (oldno != newno) {
        const db = firebase.firestore;
        const ref = db.collection('categories');

        const snapshot = await ref.where('sortno', '==', newno).get();
        if (!snapshot.empty) {
            snapshot.forEach(doc => {
                if (doc.id != oldData.id) {
                    const sortno = {sortno: oldno};
                    ref.doc(doc.id).update(sortno).then(() => {
                        return;
                    }).catch(error => {
                        console.log(error);
                    });
                }
            });
        }
    } 
}

//for sortno switching function
async function sortnoSwichingFromIndex(id, index, data) {
    const oldno = index ? Number(index) : 0;
    const newno = data.sortno ? Number(data.sortno) : 0;
    if (oldno != newno) {
        const db = firebase.firestore;
        const ref = db.collection('categories');

        const snapshot = await ref.where('sortno', '==', newno).get();
        if (!snapshot.empty) {
            snapshot.forEach(doc => {
                if (doc.id != id) {
                    const sortno = {sortno: oldno};
                    ref.doc(doc.id).update(sortno).then(() => {
                        return;
                    }).catch(error => {
                        console.log(error);
                    });
                }
            });
        }
    }
}

export default CategoryEdit;