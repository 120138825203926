import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {FirestoreCollection} from 'react-firestore';

import CategoryEdit from './CategoryEdit';
import TitleGet from '../TitleGet';
import TitleEdit from '../TitleEdit';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },

    table: {
        minWidth: 100,
    },

    cellpointer: {
        cursor: 'pointer',
        color: '#3443f6',
    },
});

class DataTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isEdit: false,
            isTitle: false,
            data: null,
        };

        this.openEdit = this.openEdit.bind(this);
        this.openTitle = this.openTitle.bind(this);
    }
    
    openTitle = (data) => this.setState({isTitle: true, data: data});
    closeTitle = () => this.setState({isTitle: false, data: null});

    openEdit = (data) => this.setState({isEdit: true, data: data});
    closeEdit = () => this.setState({isEdit: false, data: null});

    render() {
        const {classes, dataArr, lang} = this.props;
        const totalCount = Object.keys(dataArr).length + 1;

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: '150'}} className={classes.cellpointer} onClick={()=> this.openEdit(null)}>New Category</TableCell>
                            <TableCell>No</TableCell>
                            <TableCell>Hint</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>isUse</TableCell>
                            <TableCell>isManager</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataArr.map(data =>
                            <TableRow key={data.id}>
                                <TableCell component='th' scope='row' className={classes.cellpointer} onClick={() => this.openEdit(data)}>{data.id}</TableCell>
                                <TableCell>{data.sortno}</TableCell>
                                <TableCell>{data.hint}</TableCell>
                                <TableCell className={classes.cellpointer} onClick={() => this.openTitle(data)}>
                                    <TitleGet store='category' lang={lang} docId={data.id}/>
                                </TableCell>
                                <TableCell>{data.isUse == 1 ? 'Y' : 'N'}</TableCell>
                                <TableCell>{data.isManager == 1 ? 'Y' : 'N'}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>

                <CategoryEdit 
                    show={this.state.isEdit}
                    handleClose={this.closeEdit}
                    data={this.state.data}
                    total={totalCount}
                />

                <TitleEdit 
                    show={this.state.isTitle}
                    handleClose={this.closeTitle}
                    docId={this.state.data ? this.state.data.id : ''}
                    lang={lang}
                    store={'category'}
                />
            </Paper>
        )
    }
}

DataTable.propTypes = {
    classes: PropTypes.object.isRequired,
    dataArr: PropTypes.array.isRequired,
};

const MyTable = withStyles(styles)(DataTable);

const Category = (props) =>
<FirestoreCollection
    path="categories"
    sort="sortno:asc"

    render={({ isLoading, data }) =>
        isLoading ? 
        <p>Loading Data.</p> 
        : <MyTable dataArr={data} lang={props.lang}/>
    }
/>

export default Category;