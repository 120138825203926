import React from "react";
import { firebase } from "../../firebase";
import blue from "@material-ui/core/colors/blue";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

/////////////////////////////////////////////////////////////////////////////////////////////////////////
const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  icon: {
    margin: theme.spacing(2)
  },
  iconHover: {
    margin: theme.spacing(2),
    "&:hover": {
      color: blue[800]
    }
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
});

class ToolTypeEdit extends React.PureComponent {
  constructor(props){
    super(props);

    this.state = {
      hint: '',
      sortno: 0,
      isProcessing: false
    }
    this.handleSave = this.handleSave.bind(this);
    this.updateInput = this.updateInput.bind(this);
  }

  handleSave = (e) => {
    e.preventDefault();

    const {
      hint,
      sortno
    } = this.state;
    
    var docData = {
      hint: hint ? hint : '',
      sortno: sortno ? Number(sortno) : 0, //required
      createdAt: firebase.FieldValue.serverTimestamp()
    };

    const db = firebase.firestore;
    const data = this.props.data;

    if (data) { //update
      const dataRef = db.collection('tool_types').doc(data.id);  
      dataRef.update(docData)
        .then(()=>{
          this.props.handleClose();
        })
        .catch( error => {
          this.setState({
            error:error,
            isProcessing: false
          });
          console.log('Error: ToolTypeEdit-',error);
        });
    } else { //new
      docData.cnt = 0;
      db.collection('tool_types').add(docData)
        .then(()=>{
          this.props.handleClose();
        })
        .catch( error => {
          this.setState({
            error:error,
            isProcessing: false
          });
          console.log('Error: ToolTypeEdit-',error);
        });
    }
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.show){
      const data = nextProps.data;
      if(data){
        this.setState({
          hint: data.hint ? data.hint : '',
          sortno: data.sortno ? data.sortno : 0,
        });  
      }else{
        this.setState({
          hint : '',
          sortno:  0,
        });  
      }
    }
    // console.log('ToolTypeEdit:componentWillReceiveProps', nextProps);
  }

  updateInput = (e) => {
    this.setState({
      [e.target.id]: e.target.type === 'number' ? Number(e.target.value) : e.target.value
    });
  }

  // render() ///////////////////////////////
  render() {
    const {show, handleClose} = this.props;

    if(!show) {
      return null;
    }

    const {
      hint,
      sortno,
      isProcessing
    } =  this.state;

    const docId = this.props.data? ( this.props.data.id ? this.props.data.id : 'newID') : 'newID';
    const isInvalid = isProcessing;

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        scroll='paper'
        // style={{height:'500px', minHeight:'500px'}}
        style={{maxHeight:'700px'}}
      >
      <DialogTitle id="form-dialog-title">ID :{docId}</DialogTitle>
        <DialogContent>
          <TextField
              label='Hint'
              id='hint'
              value={hint}
              onChange={this.updateInput}
            />
            &nbsp;&nbsp;&nbsp;
            <TextField
              label='Sort index'
              type='number'
              id='sortno'
              value={sortno}
              onChange={this.updateInput}
            />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" className={styles.button} disabled={isInvalid} onClick={this.handleSave}>
              Save
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button variant="contained" className={styles.button} onClick={this.props.handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ToolTypeEdit;
