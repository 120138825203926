export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const LANDING = '/';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const MANAGE = '/admin';

export const M_TOOL = '/manage/tool';
export const M_TOOL_NEW = '/manage/tool_new';
export const M_TOOL_EDIT = '/manage/tool/:name';

export const M_FOOD = '/manage/food';
export const M_FOOD_NEW = '/manage/food_new';
export const M_FOOD_EDIT = '/manage/food/:name';

export const M_TOOLTYPE = '/manage/tooltype';
export const M_TOOLTYPE_NEW = '/manage/tooltype_new';
export const M_TOOLTYPE_EDIT = '/manage/tooltype/:name';

export const M_FOODTYPE = '/manage/foodtype';
export const M_FOODTYPE_NEW = '/manage/foodtype_new';
export const M_FOODTYPE_EDIT = '/manage/foodtype/:name';
