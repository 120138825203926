import React from 'react';
import {firebase} from '../../../firebase';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import blue from '@material-ui/core/colors/blue';

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end'
    },

    icon: {
        margin: theme.spacing(2)
    },

    iconHover: {
        margin: theme.spacing(2),
        '&:hover': {
            color: blue[800]
        }
    },

    button: {
        margin: theme.spacing(1)
    },

    input: {
        display: 'none'
    },
});

class FoodActionsUploadFile extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            actionStateText: "loading...",
            resultStateText: "loading..."
        };

        this.handleUploadFile = this.handleUploadFile.bind(this);
    }

    handleUploadFile = (e) => {
        e.preventDefault();
        const data = this.props.data;
        const actionFile = this.inputAction.files[0];
        const resultFile = this.inputResult.files[0];

        if (actionFile == null && resultFile == null) {
            this.props.handleClose();

        } else if (actionFile != null && resultFile == null) {
            putActionFile(data, actionFile).then(() => {
                this.props.handleClose();
            });

        } else if (actionFile == null && resultFile != null) {
            putResultFile(data, resultFile).then(() => {
                this.props.handleClose();
            });

        } else {
            Promise.all([
                putActionFile(data, actionFile), 
                putResultFile(data, resultFile)
            ]).then(() => {
                this.props.handleClose();
            });
        }
    }

    render() {
        const {data, show, handleClose} = this.props;
        if (!show) {return null;}

        fetchAction(data).then(result =>{
            if (result.a == true) {
                this.setState({actionStateText: "파일이 등록되어 있습니다."})
            } else {
                this.setState({actionStateText: "파일이 등록되어 있지 않습니다."})
            }
        });

        fetchResult(data).then(result => {
            if (result.r == true) {
                this.setState({resultStateText: "파일이 등록되어 있습니다."})
            } else {
                this.setState({resultStateText: "파일이 등록되어 있지 않습니다."})
            }
        });

        return (
            <Dialog
                open={show}
                onClose={handleClose}
                scroll='paper'
                style={{maxHeight: '700px'}}
            >
                <DialogTitle>
                    ID_: {data.id}
                    <br/>
                    HINT_: {data.hint}
                </DialogTitle>
                <br/>
                <DialogTitle>
                    a_:&nbsp; {this.state.actionStateText}
                    <br/>
                    <input className='form-control' ref={ref => {this.inputAction = ref;}} type='file'/>
                </DialogTitle>
                
                <br/>
                <DialogTitle>
                    r_:&nbsp; {this.state.resultStateText}
                    <br/>
                    <input className='form-control' ref={ref => {this.inputResult = ref;}} type='file'/>
                </DialogTitle>

                <br/>
                <DialogActions>
                    <Button variant='contained' color='primary' className={styles.button} onClick={this.handleUploadFile}>
                        Upload
                    </Button>

                    <Button variant='contained' className={styles.button} onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

async function putActionFile(data, file) {
    const storageRef = firebase.storage.ref();
    const filename = 'food_actions/' + data.id + '/a';
    const ref = storageRef.child(filename);

    await ref.put(file).then(() => {
        data.a = true;
        return;
    }).catch(error => {
        console.log(error);
        return;
    });
}

async function putResultFile(data, file) {
    const storageRef = firebase.storage.ref();
    const filename = 'food_actions/' + data.id + '/r';
    const ref = storageRef.child(filename);

    await ref.put(file).then(() => {
        data.r = true;
        return;
    }).catch(error => {
        console.log(error);
        return;
    });
}

function fetchAction(data) {
    return new Promise(resolve => {
        if(data.a != null) {resolve(data);}
        const storageRef = firebase.storage.ref();
        const filename = 'food_actions/' + data.id + '/a';
        const ref = storageRef.child(filename);

        ref.getMetadata().then(metadata => {
            data.a = metadata.size > 0 ? true : false;
            resolve(data);
        }).catch(error => {
            console.log(error);
            resolve(data);
        });
    });
}

function fetchResult(data) {
    return new Promise(resolve => {
        if (data.r != null) {resolve(data);}
        const storageRef = firebase.storage.ref();
        const filename = 'food_actions/' + data.id + '/r';
        const ref = storageRef.child(filename);

        ref.getMetadata().then(metadata => {
            data.r = metadata.size > 0 ? true : false;
            resolve(data);
        }).catch(error => {
            console.log(error);
            resolve(data);
        });
    });
}

export default FoodActionsUploadFile;