import { auth } from "./firebase";
import firebase from "firebase/app";
// import firebase from "firebase";

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) => 
  auth.createUserWithEmailAndPassword(email, password);

// Sign In
export const doSignInWithEmailAndPassword = (email, password) => 
  auth.signInWithEmailAndPassword(email, password);

// Sign Out
export const doSignOut = () =>
  auth.signOut();

// Password Reset
export const doPasswordReset = (email) =>
  auth.sendPasswordResetEmail(email);

export const doSignInWithPopup = (provider) =>
  auth.signInWithPopup(provider);

// Password Change
export const doPasswordUpdate = (password) =>
  auth.currentUser.updatePassword(password);

export const fbProvider = new firebase.auth.FacebookAuthProvider()
