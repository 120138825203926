import React from 'react';

import SignOutButton from "../SignOut";
import AuthUserContext from "../Session/AuthUserContext";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';

const Navigation = () =>
  <AuthUserContext.Consumer>
    { authUser => authUser ?
        <MyAppBar />
      : null
    }
  </AuthUserContext.Consumer>

const styles = {
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

const NavigationAuth = (props) => {
  const {classes} = props;
  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
          <MenuIcon />
        </IconButton>

        {/* <ul className={'left'}>
          <li><Link to={routes.HOME}>
            Home
          </Link></li>
          <li><Link to={routes.ACCOUNT}>
            Account
          </Link></li>
        </ul> */}
        <Typography variant="title" color="inherit" className={classes.flex}>
            Sharfoo
        </Typography>
        <SignOutButton />
      </Toolbar>
    </AppBar>
  );
}
const MyAppBar = withStyles(styles)(NavigationAuth);

export default Navigation;