import React from "react";
import { firebase } from "../../firebase";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import blue from "@material-ui/core/colors/blue";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

/////////////////////////////////////////////////////////////////////////////////////////////////////////
const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  icon: {
    margin: theme.spacing(2)
  },
  iconHover: {
    margin: theme.spacing(2),
    "&:hover": {
      color: blue[800]
    }
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
});

class CheckDlg extends React.PureComponent {
  constructor(props){
    super(props);

    this.state = {
      isUse: props.isUse ? true: false,
      isProcessing: false
    }
    this.handleSave = this.handleSave.bind(this);
    this.updateInput = this.updateInput.bind(this);
  }

  handleSave = (e) => {
    e.preventDefault();

    const {
      dbType,field, data
    } = this.props;

    const {
      isUse,
    } = this.state;

    // console.log('save,this.state:',data,field, this.state);

    var docData = {
      [field]: isUse ? true: false,
      createdAt: firebase.FieldValue.serverTimestamp()
    };

    const db = firebase.firestore;
    if(data){
      db.collection(dbType).doc(data.id).update(docData)
      .then(()=>{
        this.props.handleClose();
      })
      .catch( error => {
        this.setState({
          error:error,
          isProcessing: false
        });
        console.log('Error: check Edit-',error);
      });
    }
  }

  componentWillReceiveProps(nextProps){
    // console.log('CheckDlg componentWillReceiveProps:', nextProps);

    if (nextProps.show){
      const data = nextProps.data;
      if(data){
        this.setState({
          isUse: nextProps.isUse ? nextProps.isUse : false,
        });    
      }else{
        this.setState({
          isUse: false,
        });    
      }
    }
  }

  updateInput = (e) => {
    if (e.target.type === 'number') {
      this.setState( {
        [e.target.id]: Number(e.target.value) 
      });
    } else if (e.target.type === 'checkbox') {
      this.setState({
        [e.target.id]: e.target.checked
      });
    } else  {
      this.setState( {
        [e.target.id]: e.target.value
      });
    }
  }

  // render() ///////////////////////////////
  render() {
    const {show, handleClose,field, data} = this.props;
    if(!show) {
      return null;
    }
    const {
      isUse,
      // error,
      isProcessing
    } = this.state;

    const isInvalid = isProcessing;

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        scroll='paper'
        // style={{height:'500px', minHeight:'500px'}}
        style={{maxHeight:'700px'}}
      >
        <DialogTitle id="form-dialog-title">ID :{data.id}</DialogTitle>
        <DialogContent>
          <FormControlLabel control={<Checkbox id='isUse' checked={isUse ? true: false}/>} onChange={this.updateInput} label={field}/>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" className={styles.button} disabled={isInvalid} onClick={this.handleSave}>
              Save
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button variant="contained" className={styles.button} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CheckDlg;
