import React from 'react';
import appstore from './appstore.jpg';
import playstore from './playstore.jpg';

const PolicyPage = () => {

  const openAppStore = () => {
    console.log('openAppStore');
    if(navigator.userAgent.match(/android/i)) {
      document.location.replace("http://play.google.com/store/apps/details?id=sharfoo.com.app");
        } else if((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i))){
      document.location.replace("https://itunes.apple.com/app/id1447211006");
        }else{
          document.location.replace("https://itunes.apple.com/app/id1447211006");
        }
    
      }

  const openPlayStore = () => {
    console.log('openPlayStore');
    if(navigator.userAgent.match(/android/i)) {
      document.location.replace("http://play.google.com/store/apps/details?id=sharfoo.com.app");
        } else if((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i))){
      document.location.replace("https://itunes.apple.com/app/id1447211006");
        }else{
          document.location.replace("http://play.google.com/store/apps/details?id=sharfoo.com.app");
        }
  }

  return (
    <div>
      <p style={{textAlign:'center', margin:0}} >
        <img src={appstore} alt='icon'  style={{display:'inline-block', height:'41px',cursor:'pointer'}} onClick={() => openAppStore()}/>
        <img src={playstore} alt='icon' style={{display:'inline-block', height:'41px',cursor:'pointer'}} onClick={() => openPlayStore()}/>
      </p>
      <p style={{textAlign:'center'}}>&copy; 2017 Sharfoo.com.</p>
      </div>
  );
};

export default PolicyPage;