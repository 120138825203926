import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/RestaurantMenu';

import {firebase} from '../../../firebase';

class FoodActionsTool extends React.Component {
    state ={
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    }

    handleMenuItem = (data) => {
        if (this.props.data.toolId == data.id) {
            this.handleClose();
            return;
        }
        
        var docData = {
            toolId: data.id ? data.id : '',
            createdAt: firebase.FieldValue.serverTimestamp()
        };

        const actionId = this.props.data.id;
        const db = firebase.firestore;
        const ref = db.collection('food_actions').doc(actionId);
        ref.update(docData).then(() => {
            this.handleClose();
        }).catch(error => {
            console.log(error);
        });
    }
    
    handleSave = () => {
        const text = String(document.getElementById('input').value);
        if (text == '') {return;}

        const actionId = this.props.data.id;
        var docData ={
            toolId: text ? text : '',
            createdAt: firebase.FieldValue.serverTimestamp()
        };

        updateTool(actionId, docData, result => {
            if (Boolean(result)) {
                this.handleClose();
            } else {
                alert("도구가 존재하지 않습니다.")
            }
        });
    }

    handleKeyDown = (e) => {
        const searchText = String(e.target.value);
        if (searchText == '') {return;}
        if (e.key === 'Enter') {
            const actionId = this.props.data.id;
            var docData = {
                toolId: searchText ? searchText : '',
                createdAt: firebase.FieldValue.serverTimestamp()
            };

            updateTool(actionId, docData, result => {
                if(Boolean(result)) {
                    this.handleClose();
                } else {
                    alert("도구가 존재하지 않습니다.")
                }
            });
        }
    }

    render() {
        const anchorEl = this.state.anchorEl;
        const open = Boolean(anchorEl);

        const dataArr = [
            // {id: ''                    , hint: '도구 삭제'},
            {id: 'lBVhoGm5L9on3B7T6Us9', hint: '식칼'},
            {id: 'oLBmCYeN33Rxfdb5SJJN', hint: '감자칼'},
            {id: '4vmjHfSaHI2ItG5B6hoc', hint: '절구'},
            {id: 'ZvyWcbYL1lRji8yx6eul', hint: '스푼'},
        ];

        return (
            <div>
                <IconButton
                    aria-owns={open ? 'fade-menu' : undefined}
                    aria-haspopup='true'
                    onClick={this.handleClick}
                >
                    <EditIcon/>
                </IconButton>

                <Menu 
                    id='fade-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={this.handleClose}
                    TransitionComponent={Fade}
                >
                    <div style={{margin: '15px'}}>
                        <TextField id='input' onKeyPress={(e) => this.handleKeyDown(e)} placeholder='직접 입력' style={{width: '100px'}}/>
                        <Button
                            onClick={this.handleSave}
                            style={{height: '30px', width: '25px'}}
                        >
                            저장
                        </Button>
                    </div>
                    
                    {dataArr.map(data =>
                        <MenuItem onClick={() => this.handleMenuItem(data)}>
                            -&nbsp;&nbsp;{data.hint}
                        </MenuItem>
                    )}
                </Menu>
            </div>
        )
    }
}

async function updateTool(actionId, data, completed) {
    const isExists = await isExistsTool(data.toolId);
    if (isExists) {
        const db = firebase.firestore;
        const ref = db.collection('food_actions').doc(actionId);
        
        ref.update(data).then(() => {
            completed(true);
        }).catch(error => {
            console.log(error);
            completed(false);
        });
    } else {
        completed(false);
    }
}

async function isExistsTool(toolId) {
    const db = firebase.firestore;
    const ref = db.collection('tools').doc(toolId);
    try {
        const snapshot = await ref.get();
        return snapshot.exists;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export default FoodActionsTool;