import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import registerServiceWorker from './registerServiceWorker';

import firebase from '@firebase/app';
// import './firebase';
import '@firebase/firestore';
import { FirestoreProvider } from 'react-firestore';

// const firestore = firebase.firestore();
// const settings = {/* your settings... */ timestampsInSnapshots: true};

// firestore.settings(settings);

ReactDOM.render(
  <FirestoreProvider firebase={firebase}>
    <App />
    </FirestoreProvider>,
  document.getElementById('root'),
);
registerServiceWorker();
