import React from 'react';
import Policy from '../Policy';
import home from './home.jpg';

const LandingPage = () => {
  return (
    <div>
      <p style={{textAlign:'center', margin:0}} >        
        <img src={home} alt={'home'} style={{display:'inline-block', height:'302px',}}/>
      </p>
      <Policy/>
    </div>
  );
};

export default LandingPage;