import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { SignUpLink } from "../SignUp";
import { auth } from "../../firebase";
import * as routes from '../../constants/routes';

// const style= {
//   height:200,
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center'
// };

// const style1= {
//   height:40,
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center'
// };

const styles = theme => ({
  root: {
    width: '400px',
    marginTop: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowX: 'auto',
  },
  form: {
    marginLeft: '20px',
    marginRight: '20px'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
  },
  table: {
    minWidth: 400,
  },
  button: {
    margin: theme.spacing(1),
  },
  cellpointer:{
    cursor:'pointer',
    color: '#3443f6',
  }
});

const SignInPage = ({classes, history}) =>
  <Paper className={classes.root}>
    <h2 align='center'>Log In</h2>
    <div className={classes.form}>
      <div>
        <SignInForm history={history} classes={classes} />
        {/* <SignInFacebookButton /> */}
      </div>
      <div>
        <SignUpLink />
      </div>
    </div>
  </Paper>

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  isProcessing: false
};

const SignInFacebookButton = () =>
  <button class="sign-button"
    type="button"
    onClick={auth.doSignOut} 
    >
    Facebook Login
  </button>

class SignInForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.onSubmit = this.onSubmit.bind(this);
    this.authenticate = this.authenticate.bind(this);
  }

  onSubmit = (event) => {
    console.log('onSubmit')
    const {
      email,
      password
    } = this.state;

    const {
      history
    } = this.props;

    this.setState(byPropKey('isProcessing', true));

    auth.doSignInWithEmailAndPassword(email, password)
    .then(() => {
      this.setState({ ...INITIAL_STATE});
      history.push(routes.HOME);
    })
    .catch(error => {
      this.setState(byPropKey('error', error));
      this.setState(byPropKey('isProcessing', false));
    });

    event.preventDefault();
  }

  authenticate = (e) => {
    this.setState(byPropKey('isProcessing', true));
    console.log('Trying to auth with_ ' + e.target.id);

    const provider = auth.fbProvider;
    const {
      history
    } = this.props;

    auth.doSignInWithPopup(provider)
      .then(()=>{
        this.setState({ ...INITIAL_STATE});
        history.push(routes.HOME);  
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
        this.setState(byPropKey('isProcessing', false));  
      });

//  auth.languageCode = 'fr_FR';
//  auth.useDeviceLanguage();
  

    this.setState(byPropKey('isProcessing', false));
  }

  authHandler = (authData) => {
    console.log(authData);
  }

  updateInput = (e) => {
    this.setState({
      [e.target.id]: e.target.type === 'number' ? Number(e.target.value) : e.target.value
    });
  }

  render() {
    const {
      email,
      password,
      error,
      isProcessing
    } = this.state;

    const isInvalid = 
      password === '' ||
      email === ''  ||
      isProcessing;
    const isFacebookInvalid = isProcessing;

    const {classes} = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        <TextField
          id="email"
          label="Email"
          autoComplete="email"
          className={classes.textField}
          value={email}
          onChange={this.updateInput}
          margin="normal"
        />
        <TextField
          id="password"
          label="Password"
          className={classes.textField}
          type="password"
          autoComplete="current-password"
          margin="normal"
          value={password}
          onChange={this.updateInput}
        />
        <br />
        <br />
        <div>
          <Button variant='contained' className={styles.button} disabled={isInvalid} type="submit">
            LOG IN
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant='contained' color='primary' className={styles.button} disabled={isFacebookInvalid} type="button" onClick={this.authenticate} id="facebook">
            facebook LOGIN
          </Button>
          {error && <p>{error.message}</p>}
          </div>
      </form>
      );
  }
}

const MySignIn = withStyles(styles)(SignInPage);

export default withRouter(MySignIn);

export {
  SignInForm,
  SignInFacebookButton
};