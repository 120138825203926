import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import { firebase } from "../../firebase";

import {languages} from "../../constants/language";

export default class TitleEdit extends React.PureComponent {
  state = {
    lang: '',
    anchorEl: null,
    title:'',
    wikiUrl: '',
    isChanged: false
  };

  //Save Data ///////////////////////////
  handleSave = () => {

    const {
      docId,
      store,
    } = this.props;

    const {
      lang,
      title,
      wikiUrl,
    } = this.state;

    var docData = {
      title: title,
      wikiUrl: wikiUrl,
      createdAt: firebase.FieldValue.serverTimestamp()
    };
    const db = firebase.firestore;
    const dataRef = db.collection('languages').doc(store).collection(lang).doc(docId);

    console.log('Save Clicked:', store,lang, docId );

    dataRef.set(docData, {merge: true})
    .then(()=>{
      // this.props.handleClose();
      console.log('Save ok:' );
      this.setState({
        isChanged: false
      })
    })
    .catch( error => {
      console.log('Save error:', error );
    });
  };

  handleClickLanguage = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (event, index) => {
    const lang = languages[index]? languages[index] : '';
    this.setState({  
      lang: lang,
       anchorEl: null 
      });
      this.getTitle(this.props.store, lang, this.props.docId);
  };

  // Get Title ////////////////////
  getTitle = (store,lang,docId) => {
    this.setState({
      lang: lang,
      isChanged: false,
      title: '...........'
    });

    const db = firebase.firestore;
    const dataRef = db.collection('languages').doc(store).collection(lang).doc(docId);
    dataRef.get()
      .then(doc => {
        if(!doc.exists){
          this.setState({
            title: ''
          });
        } else {
          const data = doc.data();
          if(data){
            this.setState({
              title: data.title ? data.title: '',
              wikiUrl: data.wikiUrl ? data.wikiUrl : '',
              isChanged: false
            });  
          }else {
            this.setState({
              title: '',
              wikiUrl: '',
            });  
          }
        }
      }).catch(err => {
        this.setState({
          title: '',
          wikiUrl: '',
        });
    });
  }

  updateInput = (e) => {
    this.setState({
      [e.target.id]: e.target.type === 'number' ? Number(e.target.value) : e.target.value,
      isChanged: true
    });
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.show){
      const {
        docId,
        store,
        lang
      } = nextProps;
      this.getTitle(store,lang,docId);
    }
  }

  render() {
    
    const {show, handleClose, docId} = this.props;
    if(!show) {
      return null;
    }
    const {lang, title, wikiUrl, anchorEl, isChanged} = this.state;
    const isDiable = !isChanged;

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{width:'400px'}}>ID:&nbsp;{docId}</DialogTitle>
        <DialogContent>
          <Button variant="outlined" onClick={this.handleClickLanguage} > {lang}</Button>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
          {languages.map((option, index) => (
            <MenuItem
              key={option}
              selected={index === this.state.languageIdx}
              onClick={event => this.handleMenuItemClick(event, index)}
            >
              {option}
            </MenuItem>
            ))}
          </Menu>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <TextField
            autoFocus
            // margin="dense"
            id="title"
            label="Title"
            type="text"
            value={title}
            onChange={this.updateInput}
            style={{width:'270px'}}
          />
          <br/>
          <TextField
            autoFocus
            // margin="dense"
            id="wikiUrl"
            label="wiki Url"
            type="text"
            value={wikiUrl}
            onChange={this.updateInput}
            style={{width:'400px'}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleSave} 
            color="primary"
            disabled={isDiable}
             >
            Save
          </Button>
          <Button onClick={handleClose} >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

