import React from 'react';

import FoodPage from './FoodPage';
import Foods from './Foods';
import Tool from './Tool';
import ToolType from "./ToolType";
import FoodType from "./FoodType";
// import Word from "./Word";
// import Document from "./Document";
import MenuGroup from "./MenuGroup"
import MenuMain from "./MenuMain"
import Category from "./Category/Category";

import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Button } from '@material-ui/core';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import {languages} from "../../constants/language";
import AuthUserContext from "../Session/AuthUserContext";
import withAuthorization from "../Session/withAuthorization";


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  tab: {
    minWidth: 50,
    width: 100,
  }
});

class ManagePage extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      tabValue: "manage",
      languageIdx: 0,
      anchorEl: null,
      // currentPage: 0,
      // anchorPage: null,
    };
    this.handleTabChange =  this.handleTabChange.bind(this);

    this.handleClickLanguage =  this.handleClickLanguage.bind(this);
    this.handleMenuItemClick =  this.handleMenuItemClick.bind(this);
    this.handleMenuItemClose =  this.handleMenuItemClose.bind(this);

    // this.handleClickPage =  this.handleClickPage.bind(this);
    // this.handlePageMenuClick =  this.handlePageMenuClick.bind(this);
    // this.handlePageMenuClose =  this.handlePageMenuClose.bind(this);
  }

  handleTabChange = (event, tabValue) => {
    this.setState({tabValue});
  }

  handleClickLanguage = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleMenuItemClick = (event, index) => {
    this.setState({ languageIdx: index, anchorEl: null });
  };
  handleMenuItemClose = () => {
    this.setState({ anchorEl: null });
  };

  // handleClickPage = event => {
  //   this.setState({ anchorPage: event.currentTarget });
  // };

  // handlePageMenuClick = (event, index) => {
  //   this.setState({ currentPage: index, anchorPage: null });
  // };
  // handlePageMenuClose = () => {
  //   this.setState({ anchorPage: null });
  // };

  render() {
    const {tabValue, anchorEl, languageIdx} = this.state;
    const { classes} = this.props;
    const language = languages[languageIdx] ? languages[languageIdx] : '' ;
    return (
      <AuthUserContext.Consumer>
        {() =>
          <div>
          <Grid container spacing={0}>
              <Grid item xs={10}>
                <Tabs 
                  value={tabValue}
                  variant="scrollable"
                  onChange={this.handleTabChange}>
                    <Tab classes={{ root: classes.tab }} value="manage"    label="Manage"   />
                    <Tab classes={{ root: classes.tab }} value="tool"      label="Tool"     />
                    <Tab classes={{ root: classes.tab }} value="food"      label="Food"     />
                    <Tab classes={{ root: classes.tab }} value="foods"     label="Foods"    />
                    <Tab classes={{ root: classes.tab }} value="tooltype"  label="ToolType" />
                    <Tab classes={{ root: classes.tab }} value="foodtype"  label="FoodType" />
                    <Tab classes={{ root: classes.tab }} value="menugroup" label="MenuGroup"/>
                    <Tab classes={{ root: classes.tab }} value="menumain"  label="MenuMain" />
                    <Tab classes={{ root: classes.tab }} value="category"  label="Category" />
                  </Tabs>
              </Grid>
              <Grid container justify='flex-end' item xs={2}>
              <Button variant="outlined" className={classes.button} onClick={this.handleClickLanguage} > {language}</Button>
                <Menu
                  id="lock-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={this.handleMenuItemClose}
                >
                {languages.map((option, index) => (
                  <MenuItem
                    key={option}
                    selected={index === this.state.languageIdx}
                    onClick={event => this.handleMenuItemClick(event, index)}
                  >
                    {option}
                  </MenuItem>
                  ))}
                </Menu>
              </Grid>
            </Grid>
            <div style={{padding:'5px'}}>
              {tabValue === 'manage'    && <br />}
              {tabValue === 'tool'      && <Tool      lang={language}/>}
              {tabValue === 'food'      && <FoodPage  lang={language}/>}
              {tabValue === 'foods'     && <Foods     lang={language}/>}
              {tabValue === 'tooltype'  && <ToolType  lang={language}/>}
              {tabValue === 'foodtype'  && <FoodType  lang={language}/>}
              {tabValue === 'menugroup' && <MenuGroup lang={language}/>}
              {tabValue === 'menumain'  && <MenuMain  lang={language}/>}
              {tabValue === 'category'  && <Category  lang={language}/>}
            </div>
          </div>
        }
        </AuthUserContext.Consumer>
    );
  }
}

const MyManagePage = withStyles(styles)(ManagePage);
const authCondition = (authUser) => {
  if(!authUser)
  {
    return false;
  }
  return true;
};

export default withAuthorization(authCondition)(MyManagePage);

