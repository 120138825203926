
import firebase from "firebase/app";
// import * as admin from "firebase-admin";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
// import  from "./sharfoo-9f544-firebase-adminsdk-10uuc-51b7d5248f";

//old apiKey: "AIzaSyAdSa0yQ4mMnaA-xivcrarvgxQWuG5_Qv8"
const config = {
  apiKey: "AIzaSyCWK0i1ZtAVXMnDS8wqgGkOeMP_AdO8c9o",
  authDomain: "sharfoo-9f544.firebaseapp.com",
  databaseURL: "https://sharfoo-9f544.firebaseio.com",
  projectId: "sharfoo-9f544",
  storageBucket: "sharfoo-9f544.appspot.com",
  messagingSenderId: "477370827684"
};

if (!firebase.apps.length){
  firebase.initializeApp(config);
  console.log('firebase.initializeApp~~~~~~~');
} else {
  console.log('firebase.~~~~~~~', );
}

const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();
const FieldValue = firebase.firestore.FieldValue;
const Timestamp = firebase.firestore.Timestamp;

export default firebase;

export {
  auth,
  firestore,
  storage,
  FieldValue,
  Timestamp
}
