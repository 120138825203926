import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import AccountPage from '../Account';
import LandingPage from '../Landing';
import ManagePage from '../Manage';
import Navigation from '../Navigation';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import withAuthentication from "../Session/withAuthentication";
import * as routes from "../../constants/routes";

import './index.css';

const App = () =>
  <Router>
    <div className='container-body'>
      <Navigation />

      <Route exact path={routes.SIGN_UP} component={ ()=> <SignUpPage /> } />
      <Route exact path={routes.SIGN_IN} component={ ()=> <SignInPage /> } />
      <Route exact path={routes.HOME} component={ ()=> <LandingPage /> } />
      <Route exact path={routes.ACCOUNT} component={ ()=> <AccountPage /> } />
      <Route exact path={routes.LANDING} component={ ()=> <LandingPage /> } />
      <Switch>
        <Route path={routes.MANAGE + "/:name"} component={ ()=> <ManagePage /> } />
        <Route path={routes.MANAGE} component={ ()=> <ManagePage /> } />
      </Switch>
    </div>
  </Router>

export default withAuthentication(App);
