import React from "react";
import { firebase } from "../../firebase";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import blue from "@material-ui/core/colors/blue";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

/////////////////////////////////////////////////////////////////////////////////////////////////////////
const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  icon: {
    margin: theme.spacing(2)
  },
  iconHover: {
    margin: theme.spacing(2),
    "&:hover": {
      color: blue[800]
    }
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
});

class FoodEdit extends React.PureComponent {
  constructor(props){
    super(props);

    this.state = {
      isLiter: false,
      isCount: false,
      isBase: false,
      isProcessing: false
    }
    this.handleSave = this.handleSave.bind(this);
    this.updateInput = this.updateInput.bind(this);
  }

  handleSave = (e) => {
    e.preventDefault();

    const {
      hint,
      size,
      isLiter,
      isCount,
      isBase,
      weight,
      length,
    } = this.state;

    console.log('save,this.state:',this.state);

    var docData = {
      hint: hint ? hint : '',
      isLiter: isLiter ? true : false,
      isCount: isCount ? true : false,
      isBase: isBase  ? true : false,
      size: size ? Number(size): 0,
      weight: weight ? Number(weight) : 0,
      length: length ? Number(length) : 0,
      createdAt: firebase.FieldValue.serverTimestamp()
    };

    const db = firebase.firestore;
    const data = this.props.data;

    if(data){
      db.collection('foods').doc(data.id).update(docData)
      .then(()=>{
        this.props.handleClose();
      })
      .catch( error => {
        this.setState({
          error:error,
          isProcessing: false
        });
        console.log('Error: food Edit-',error);
      });
    } else {
      db.collection('foods').add(docData)
      .then(()=>{
        this.props.handleClose();
      })
      .catch( error => {
        this.setState({
          error:error,
          isProcessing: false
        });
        console.log('Error: FoodTypeEdit-',error);
      });
    }
  }

  componentWillReceiveProps(nextProps){
    // console.log('FoodEdit componentWillReceiveProps:', nextProps);

    if (nextProps.show){
      const data = nextProps.data;
      if(data){
        this.setState({
          hint: data.hint ? data.hint : '',
          isLiter: data.isLiter ? true : false,
          isCount: data.isCount ? true : false,
          isBase: data.isBase ? true : false,
          size: data.size ? Number(data.size) : 0,
          weight: data.weight ? Number(data.weight) : 0,
          length: data.length ? Number(data.length) : 0,
        });    
      }else{
        this.setState({
          hint: '',
          isLiter: false,
          isCount: false,
          isBase: false,
          size: 4,
          weight: 0,
          length: 0,
        });    
      }
    }
  }

  updateInput = (e) => {
    if (e.target.type === 'number') {
      this.setState( {
        [e.target.id]: Number(e.target.value) 
      });
    } else if (e.target.type === 'checkbox') {
      this.setState({
        [e.target.id]: e.target.checked
      });
    } else  {
      this.setState( {
        [e.target.id]: e.target.value
      });
    }
  }

  // render() ///////////////////////////////
  render() {
    const {show, handleClose} = this.props;
    if(!show) {
      return null;
    }
    const {
      hint,
      size,
      isLiter,
      isCount,
      isBase,
      weight,
      length,
      // error,
      isProcessing
    } = this.state;

    const docId = this.props.data? ( this.props.data.id ? this.props.data.id : 'newID') : 'newID';
    const isInvalid = isProcessing;

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        scroll='paper'
        // style={{height:'500px', minHeight:'500px'}}
        style={{maxHeight:'700px'}}
      >
        <DialogTitle id="form-dialog-title">ID_: {docId}</DialogTitle>
        <DialogContent>
          <TextField
            label='Hint'
            id='hint'
            value={hint}
            onChange={this.updateInput}
          />
          &nbsp;&nbsp;&nbsp;
          <FormControlLabel control={<Checkbox id='isLiter' checked={isLiter ? true: false}/>} onChange={this.updateInput} label='Volume'/>
          &nbsp;
          <FormControlLabel control={<Checkbox id='isCount' checked={isCount ? true: false} />} onChange={this.updateInput} label='Count'/>
          &nbsp;
          <FormControlLabel control={<Checkbox id='isBase' checked={isBase ? true: false} />} onChange={this.updateInput} label='Base'/>
          <br/>
          <TextField
            label='Size'
            id='size'
            value={size}
            type='number'
            onChange={this.updateInput}
          />
          &nbsp;&nbsp;&nbsp;
          <TextField
            label='Weight'
            id='weight'
            type='number'
            value={weight}
            onChange={this.updateInput}
          />
          &nbsp;&nbsp;&nbsp;
          <TextField
            label='Length'
            type='number'
            id='length'
            value={length}
            onChange={this.updateInput}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" className={styles.button} disabled={isInvalid} onClick={this.handleSave}>
              Save
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Button variant="contained" className={styles.button} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default FoodEdit;
