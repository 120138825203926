import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { FirestoreCollection } from 'react-firestore';

import PhotoSelect from "./PhotoSelect";
import FoodTypeEdit from "./FoodTypeEdit";
import TypeSelect from './TypeSelect';
import TitleEdit from "./TitleEdit";
import TitleGet from "./TitleGet";


/////////////////////////////////////////////////////////////////////////////////////////////////////////
class DataTable extends React.Component{
  
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isPhoto: false,
      isType: false,
      isTitle: false,
      data: null,
    };
  }
  openModal = (data) => this.setState({isOpen: true, data: data });
  closeModal = () => this.setState({ isOpen: false, data: null });

  openPhoto = (data) => this.setState({isPhoto: true, data: data });
  closePhoto = () => this.setState({ isPhoto: false, data: null });

  openType = (data) => this.setState({isType: true, data: data });
  closeType = () => this.setState({ isType: false, data: null });

  openTitle = (data) => this.setState({isTitle: true, data: data });
  closeTitle = () => this.setState({ isTitle: false, data: null });

  render(){

    const { classes, dataArr, lang } =  this.props;
   
    const photoId = this.state.data
                    ? (this.state.data.id? this.state.data.id:'')
                    :'';
    const photoUrl = this.state.data
                    ? (this.state.data.urlM? this.state.data.urlM:'')
                    :'';
    return(
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{width:'150'}} className={classes.cellpointer} onClick={()=> this.openModal(null)}>
                New FoodType</TableCell>
              <TableCell>Photo</TableCell>
              <TableCell>Hint</TableCell>
              <TableCell>Title</TableCell>
              <TableCell numeric>SortNo</TableCell>
              <TableCell numeric>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { dataArr.map(data =>
              <TableRow key={data.id}>
                <TableCell style={{padding:'0 2px', width:152}} component="th" scope="row" className={classes.cellpointer} onClick={() => this.openModal(data)}>
                 {data.id}</TableCell>
                <TableCell style={{padding:'0 2px'}}>{ data.urlM
                  ? <img src={data.urlM} alt='icon' style={{height:'56px',display:'flex',cursor:'pointer'}} onClick={() => this.openPhoto(data)}/>
                  : <button onClick={() => this.openPhoto(data)}> Photo </button> }
                </TableCell>
                <TableCell>{data.hint}</TableCell>
                <TableCell className={classes.cellpointer} onClick={()=> this.openTitle(data)}>
                  <TitleGet store="food_type" lang={lang} docId={data.id}/>
                </TableCell>
                <TableCell numeric>{data.sortno}</TableCell>
                <TableCell numeric className={classes.cellpointer} onClick={() => this.openType(data)}>
                  {data.cnt}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <FoodTypeEdit show={this.state.isOpen}
          handleClose={this.closeModal}
          data={this.state.data}
        />
        <PhotoSelect show={this.state.isPhoto}
          handleClose={this.closePhoto}
          docId={photoId}
          imageUrl={photoUrl}
          storeage={'foodtype_image'}
          store={'food_types'}
        />
        <TypeSelect
          show={this.state.isType}
          handleClose={this.closeType}
          docId={this.state.data ? this.state.data.id : ''}
          store={'food_types'}
        />
        <TitleEdit
          show={this.state.isTitle}
          handleClose={this.closeTitle}
          docId={this.state.data ? this.state.data.id : ''}
          lang={lang}
          store={'food_type'}          
        />
      </Paper>
    );
  }
}

DataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  dataArr: PropTypes.array.isRequired,
};

/////////////////////////////////////////////////////////////////
const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 400,
  },
  cellpointer:{
    cursor:'pointer',
    color: '#3443f6',
  }
});

const MyTable = withStyles(styles)(DataTable);

const FoodType = (props) =>
  <FirestoreCollection
    path="food_types"
    sort="sortno"
//        limit={5}
    render={({ isLoading, data }) => {
      return isLoading 
      ? <p>Loding Data.</p>
      : <MyTable dataArr={data} lang={props.lang}/>;
    }}
  />

export default FoodType;
