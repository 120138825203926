import React from "react";

// import { firebase } from "../../firebase";
import { FirestoreDocument } from 'react-firestore';

class TitleGet extends React.PureComponent{
  // constructor(props){
  //   super(props)
  //   this.state = {
  //     lang: props.lang,
  //     title:''
  //   };
  // }

  // componentDidMount(){
  //   const {store, lang, docId} = this.props;
  //   this.getTitle(store, lang, docId);  
  // }

  //  // Get Title ////////////////////
  //  getTitle = (store,lang,docId) => {
  //   this.setState({
  //     lang: lang,
  //     title: '...........'
  //   });

  //   const db = firebase.firestore;
  //   const dataRef = db.collection('languages').doc(store).collection(lang).doc(docId);
  //   dataRef.get()
  //     .then(doc => {
  //       if(!doc.exists){
  //         this.setState({
  //           title: ''
  //         });
  //       } else {
  //         const data = doc.data();
  //         if(data){
  //           this.setState({
  //             title: data.title
  //           });  
  //         }else {
  //           this.setState({
  //             title: ''
  //           });  
  //         }
  //       }
  //     }).catch(err => {
  //       this.setState({
  //         title: '..err..'
  //       });
  //   });
  // }

  // componentWillReceiveProps(nextProps){
  //     const {
  //       docId,
  //       store,
  //       lang
  //     } = nextProps;

  //     // if(this.state.lang !== lang){
  //       this.getTitle(store,lang,docId);
  //     // }  
  //     console.log('TitleGet componentWillReceiveProps', store, lang, docId);
  // }

  render(){
    // const {title} = this.state;
    // console.log('getTitle ' + this.props.store + '/'+ this.props.lang + '/' + this.props.docId);
    return (
      // <div>
      //   {title}
      // </div>
      <FirestoreDocument
        path={'languages/' + this.props.store + '/'+ this.props.lang + '/' + this.props.docId}
        render={({ isLoading, data }) => {
          // if (isLoading){
          //   this.state.title = '..........';
          // } else {
          //   this.state.title = data.title ? data.title : '_____';
          // }
          // const {title} = this.state;
          return (
            isLoading ? (
              "..........."
            ) : (
              <div>
                {data.wikiUrl ? '# '+data.title : ' ' + data.title}
              </div>
            )
          );
        }}
      />    
    );
  }
}

export default TitleGet;