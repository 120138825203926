import React from "react";
import {firebase} from "../../../firebase";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import blue from "@material-ui/core/colors/blue";
import { withStyles } from '@material-ui/core/styles';
import {FirestoreCollection} from 'react-firestore';
import Loading from 'react-loading';
import MaterialTable from 'material-table';

import IconButton from '@material-ui/core/IconButton';
import PhotoIcon from '@material-ui/icons/InsertPhoto';

import FoodActionsUploadFile from './FoodActionsUploadFile';
import FoodActionsTool from './FoodActionsTool';

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },

  icon: {
    margin: theme.spacing(2)
  },

  iconHover: {
    margin: theme.spacing(2),
    "&:hover": {
      color: blue[800]
    }
  },

  button: {
    margin: theme.spacing(1),
  },

  input: {
    display: 'none',
  },

  menu: {
    width: 200,
  },

  alignRight: {
    textAlign: 'right'
  },
});

class DataTable extends React.Component {
  
  constructor(props){
    super(props);

    this.state = {
      isProcessing: false,

      columns: [
        {title: 'no'      , render: rowData => rowData.tableData.id + 1},
        {title: 'actionId', field: 'id'      , cellStyle: {width: 200, minWidth: 200}, editable: false},
        {title: 'hint'    , field: 'hint'    , cellStyle: {width: 150, minWidth: 150}                 },
        {title: 'isUse'   , field: 'isUse'   , type: 'numeric'},
        {title: 'cntPhoto', field: 'cntPhoto', type: 'numeric'},
        {title: 'duration', field: 'duration', type: 'numeric'},
        {title: 'toolId'  , field: 'toolId'  , cellStyle: {width: 200, minWidth: 200}                 }, 
        {title: 'tool'    , render: rowData => (<FoodActionsTool data={rowData} />)},
        {title: 'photo'   , render: rowData => (<IconButton onClick={() => this.openPhoto(rowData)}><PhotoIcon/></IconButton>)}
      ],
    }

    this.closePhoto = this.closePhoto.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  openPhoto = (data) => this.setState({isPhoto: true, data: data});
  closePhoto = () => this.setState({isPhoto: false, data: null});

  openMenu = (data) => this.setState({isMenu: true, data: data});
  closeMenu = () => this.setState({isMenu: false, data: null});

  onRowAdd = (newData) =>
    new Promise(resolve => {
      const foodId = this.props.foodId; 

      var docData = {
        hint     : newData.hint ? newData.hint : '',
        foodId   : foodId ? foodId : '',
        toolId   : newData.toolId ? newData.toolId : '',
        isUse    : Number(newData.isUse) > 0 ? 1 : 0, //Number(newData.isUse ? newData.isUse : 0),
        cntPhoto : newData.cntPhoto == null || Number(newData.cntPhoto) < 0 || Number.isNaN(newData.cntPhoto) ? 0 : Number(newData.cntPhoto), //Number(newData.cntPhoto ? newData.cntPhoto : 0),
        duration : newData.duration == null || Number(newData.duration) < 0 || Number.isNaN(newData.duration) ? 30 : Number(newData.duration), //Number(newData.duration ? newData.duration : 30),
        createdAt: firebase.FieldValue.serverTimestamp()
    }

      const db = firebase.firestore;
      db.collection('food_actions').add(docData).then(() => {
        resolve();

      }).catch( error => {
        this.setState({
          error:error,
          isProcessing: false
        });

        resolve();
      });
    });

  onRowUpdate = (newData, oldData) =>
    new Promise(resolve => {
      const foodId = this.props.foodId; 

      var docData = {
        hint     : newData.hint ? newData.hint : '',
        foodId   : foodId ? foodId : '',
        toolId   : newData.toolId ? newData.toolId : '',
        isUse    : Number(newData.isUse) > 0 ? 1 : 0, //Number(newData.isUse ? newData.isUse : 0),
        cntPhoto : newData.cntPhoto == null || Number(newData.cntPhoto) < 0 || Number.isNaN(newData.cntPhoto) ? 0 : Number(newData.cntPhoto), //Number(newData.cntPhoto ? newData.cntPhoto : 0),
        duration : newData.duration == null || Number(newData.duration) < 0 || Number.isNaN(newData.duration) ? 30 : Number(newData.duration), //Number(newData.duration ? newData.duration : 30),
        createdAt: firebase.FieldValue.serverTimestamp()
      }

      const db = firebase.firestore;
      const dataRef = db.collection('food_actions').doc(oldData.id);
      dataRef.update(docData).then(() => {
        resolve();

      }).catch(error => {
        this.setState({
          error:error,
          isProcessing: false
        });
        console.log(error);
        resolve();
      });
    });

  onRowDelete = (data) => //not used
    new Promise(resolve => {
      const db = firebase.firestore;
      const dataRef = db.collection('food_actions').doc(data.id);
      dataRef.delete();
      resolve();
    });

  render() {
    const {foodId, dataArr, onClose} = this.props;

    const {
      columns,
    } = this.state;
    
    return (
      <DialogContent>
        <MaterialTable
          title={foodId} 
          columns={columns}
          localization={{
            pagination: {
                labelDisplayedRows: '{from}-{to} 의 {count}'
            },
            toolbar: {
                nRowsSelected: '{0} 라인(s) 선택'
            },
            header: {
                actions: ''
            },
            body: {
                emptyDataSourceMessage: '데이터가 없습니다.',
                filterRow: {filterTooltip: '필터'},
                editRow: {
                  saveTooltip: '확인',
                  cancelTooltip: '취소',
                  // deleteText: '삭제 하시겠습니까?',
                },
            },
          }}

          data={dataArr}
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: 3, //10
            pageSizeOptions: [3, 5, 10, 20]
          }}

          actions={[
            {
              icon: 'close',
              tooltip: '닫기',
              isFreeAction: true,
              onClick: onClose
            }
          ]}

          editable={{
            onRowAdd: this.onRowAdd, 
            onRowUpdate: this.onRowUpdate,
          }}
        />

        <FoodActionsUploadFile 
          show={this.state.isPhoto}
          handleClose={this.closePhoto}
          data={this.state.data}
        />
      </DialogContent>
    );
  }
}

const MyTable = withStyles(styles)(DataTable);

function FoodActionsDlg({show, handleClose, foodId}) {
  return (
    !show ?
    null : 
    <Dialog
      open={show}
      onClose={handleClose}
      scroll='paper'
      maxWidth={"lg"}
      fullWidth={true}
    >

      <FirestoreCollection
        path='food_actions'
        filter={['foodId', '==', foodId]}
        sort='createdAt:desc'

        render={({isLoading, data}) =>
          isLoading
          ? <Loading/>
          : <MyTable
            foodId={foodId}
            dataArr={data}
            onClose={handleClose}
            />
        }
      />
    </Dialog>
  );
}

export default FoodActionsDlg;
