import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';

import { firebase } from "../../firebase";
import { FirestoreCollection } from 'react-firestore';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Add from "@material-ui/icons/Add";
 
class DataTable extends React.Component{

  render() {
    const { dataArr, onDelete } = this.props;

    return (
      <List>
        {dataArr.map(data =>
          <ListItem key={data.id}>
            <ListItemText
              primary={data.id}
            />
            <ListItemSecondaryAction>
              <IconButton aria-label="Delete" onClick={()=> onDelete(data.id) }>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </List>        
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  list: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
  },
  icon: {
    margin: theme.spacing(2),
  }
});

const MyTable = withStyles(styles)(DataTable);

class TypeSelect extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dataId:''
    }
  }

  updateInput = (e) => {
    this.setState({
      [e.target.id]: e.target.type === 'number' ? Number(e.target.value) : e.target.value
    });
  }

  handleDelete = (dataId) => {

    console.log('dataId', dataId);
    const { docId, store } = this.props;
    const db = firebase.firestore;

    db.collection(store).doc(docId).collection('items').doc(dataId).delete()
    .then(() => {
      db.collection(store).doc(docId).collection('items').get().then(snap => {
        const count = snap.size;
        const docData = {
          cnt: count
        };
        db.collection(store).doc(docId).update(docData);  
        console.log('success count:', count);
      });
    });
  }

  handleSave = (e) => {
    e.preventDefault();
    const { docId, store } = this.props;
    const {dataId} = this.state;
    
    console.log('handleSave ', docId,'/' ,dataId);
    // return;

    const db = firebase.firestore;

    db.collection(store).doc(docId).collection('items').doc(dataId).set({
      createdAt: firebase.FieldValue.serverTimestamp()
    })
    .then(()=>{
      this.setState({
        dataId:'',
      });
      db.collection(store).doc(docId).collection('items').get().then(snap => {
        const count = snap.size;
        const docData = {
          cnt: count
        };
        db.collection(store).doc(docId).update(docData);  
      });
      // this.props.handleClose();
    })
    .catch( error => {
      this.setState({
        error:error,
        isProcessing: false
      });
      console.log('Error: TypeSelect-',error);
    });
  }

  render() {
    const {show, docId, handleClose, store} = this.props;
    const {dataId} =  this.state;
    const isInvalid = dataId.length < 1;

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        scroll='paper'
        // style={{height:'500px', minHeight:'500px'}}
        style={{maxHeight:'700px'}}
      >
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
        <DialogContent>
        { show === true
          ? ( <FirestoreCollection
              path={store + '/' + docId + '/items'}
              // sort="sortno:desc"
              //        limit={5}
              render={({ isLoading, data }) => {
                return isLoading
                ? 'Loding Data.'
                : (
                  <div>
                      <MyTable dataArr={data} onDelete={this.handleDelete}/>
                  </div>
                )
              }
            }
            /> )
          : null
        }
        </DialogContent>
        <DialogActions>
          <div style={{display:'flex', flexWrap:'wrap',paddingTop:'20px', paddingLeft:'40px', width:'300px'}}>
            <TextField
              autoFocus
              // margin="dense"
              id="dataId"
              type="text"
              style={{width:'185px'}}
              value={dataId}
              onChange={this.updateInput}
            />
            <IconButton aria-label="Add" disabled={isInvalid} onClick={this.handleSave}>
              <Add color='primary' />
            </IconButton>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

export default TypeSelect;