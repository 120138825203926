import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FirestoreCollection } from 'react-firestore';
import { FirestoreDocument } from 'react-firestore';

import PhotoSelect from "./PhotoSelect";
import FoodEdit from "./FoodEdit";
import CheckDlg from "./CheckDlg";
import TitleEdit from "./TitleEdit";
import TitleGet from "./TitleGet";
import './index.css';
// import Loading from 'react-loading';


/////////////////////////////////////////////////////////////////////////////////////////////////////////

class DataTable extends React.Component{
  
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isPhoto: false,
      isTitle: false,
      isVolume: false,
      isCount: false,
      isBase: false,

      data: null,
    };
    this.openModal =  this.openModal.bind(this);
  }
  // openModal1 = (event) => this.setState({isOpen: true, data: event.target.dataset });
  openModal = (data) => this.setState({isOpen: true, data: data });
  closeModal = () => this.setState({ isOpen: false, data: null });

  openPhoto = (data) => this.setState({isPhoto: true, data: data });
  closePhoto = () => this.setState({ isPhoto: false, data: null });

  openTitle = (data) => this.setState({isTitle: true, data: data });
  closeTitle = () => this.setState({ isTitle: false, data: null });

  openVolume = (data) => this.setState({isVolume: true, data: data });
  closeVolume = () => this.setState({ isVolume: false, data: null });

  openCount = (data) => this.setState({isCount: true, data: data });
  closeCount = () => this.setState({ isCount: false, data: null });

  openBase = (data) => this.setState({isBase: true, data: data });
  closeBase = () => this.setState({ isBase: false, data: null });

  render(){

    const {classes, dataArr, lang } =  this.props;

    const photoId = this.state.data
                    ? (this.state.data.id? this.state.data.id:'')
                    :'';
    const photoUrl = this.state.data
                  ? (this.state.data.urlM? this.state.data.urlM:'')
                  :'';

    // console.log(JSON.stringify(dataArr), lang);
    
    return(
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{padding:'0 2px',width:'150'}} className={classes.cellpointer} onClick={()=> this.openModal(null)}>
                New Food.</TableCell>
              <TableCell style={{padding:'0 2px', width:56}}>Photo</TableCell>
              <TableCell style={{padding:'0 2px'}}>Hint</TableCell>
              <TableCell style={{padding:'0 2px'}}>Title</TableCell>
              <TableCell style={{padding:'0 2px',textAlign:'center', width:60}}>isVolume</TableCell>
              <TableCell style={{padding:'0 2px',textAlign:'center', width:60}}>isCount</TableCell>
              <TableCell style={{padding:'0 2px',textAlign:'center', width:60}}>isBase</TableCell>
              <TableCell style={{padding:'0 2px',textAlign:'right'}}>Size</TableCell>
              <TableCell style={{padding:'0 2px',textAlign:'right'}}>weight</TableCell>
              <TableCell style={{padding:'0 2px',textAlign:'right', paddingRight:10}}>length</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataArr.map(t =>
              <FirestoreDocument
                key={t.id}
                path={'foods/' + t.id}
                render={( {isLoading, data}) => {
                  return isLoading ? (
                    <TableRow >
                    <TableCell>Loading</TableCell>
                    </TableRow>
                  ): (
                    <TableRow >
                      <TableCell component="th" scope="row" className={classes.cellpointer} style={{padding:'0 2px', width:152}} onClick={() => this.openModal(data)}>
                        {data.id}</TableCell>
                      <TableCell style={{padding:'0 2px', width:56}}>{ data.urlM
                        ? <img src={data.urlM} alt='icon' style={{height:'56px',display:'flex',cursor:'pointer'}} onClick={() => this.openPhoto(data)}/>
                        : <button onClick={() => this.openPhoto(data)}> Photo </button> }
                      </TableCell>
                      <TableCell style={{padding:'0 2px'}}>{data.hint}</TableCell>
                      <TableCell  style={{padding:'0 2px'}} className={classes.cellpointer} onClick={()=> this.openTitle(data)}>
                        <TitleGet store="food" lang={lang} docId={data.id}/>
                      </TableCell>
                      <TableCell style={{padding:'0 2px',textAlign:'center', width:60}} className={classes.cellpointer}
                        onClick={()=> this.openVolume(data)}>{data.isLiter ? 'Y': '_'}
                      </TableCell>
                      <TableCell style={{padding:'0 2px',textAlign:'center', width:60}} className={classes.cellpointer}
                        onClick={()=> this.openCount(data)}>{data.isCount ? 'Y' : '_'}
                      </TableCell>
                      <TableCell style={{padding:'0 2px',textAlign:'center', width:60}} className={classes.cellpointer}
                        onClick={()=> this.openBase(data)}>{data.isBase ? 'Y' : '_'}
                      </TableCell>
                      <TableCell numeric style={{padding:'0 2px'}}>{data.size}</TableCell>
                      <TableCell numeric style={{padding:'0 2px'}}>{data.weight}</TableCell>
                      <TableCell numeric style={{padding:'0 2px', paddingRight:10}}>{data.length}</TableCell>
                    </TableRow>
                  );
                }}
                />
            )}
          </TableBody>
        </Table>
        <FoodEdit show={this.state.isOpen}
          handleClose={this.closeModal}
          data={this.state.data}
        />
        <CheckDlg show={this.state.isVolume}
          dbType={'foods'}
          field={'isLiter'}
          handleClose={this.closeVolume}
          data={this.state.data}
          isUse={this.state.data ? this.state.data.isLiter : false}
        />
        <CheckDlg show={this.state.isCount}
          dbType={'foods'}
          field={'isCount'}
          handleClose={this.closeCount}
          data={this.state.data}
          isUse={this.state.data ? this.state.data.isCount : false}
        />
        <CheckDlg show={this.state.isBase}
          dbType={'foods'}
          field={'isBase'}
          handleClose={this.closeBase}
          data={this.state.data}
          isUse={this.state.data ? this.state.data.isBase : false}
        />
        <PhotoSelect show={this.state.isPhoto}
          handleClose={this.closePhoto}
          docId={photoId}
          imageUrl={photoUrl}
          storeage={'food_image'}
          store={'foods'}
        />
        <TitleEdit
          show={this.state.isTitle}
          handleClose={this.closeTitle}
          docId={this.state.data ? this.state.data.id : ''}
          lang={lang}
          store={'food'}          
        />
      </Paper>
    );
  }
}

DataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  dataArr: PropTypes.array.isRequired,
};


class MenuTable extends React.Component{
  
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick = (id) => (e) => {
    // console.log('onClick e:',e.target, 'id:', id);
    this.props.onSelect(id);
  }

  render(){

    const { classes, dataArr } =  this.props;
   
    return(
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {/* <TableCell style={{width:'150'}} className={classes.cellpointer} onClick={()=> this.openModal(null)}>
                New FoodType</TableCell> */}
              <TableCell style={{width:'56', padding:'0'}}>Photo</TableCell>
              {/* <TableCell>Hint</TableCell> */}
              <TableCell numeric style={{width:'56', padding:'0'}}>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { dataArr.map(data =>
              <TableRow key={data.id}>
                {/* <TableCell component="th" scope="row" className={classes.cellpointer} onClick={() => this.openModal(data)}>
                 {data.id}</TableCell> */}
                <TableCell style={{padding:'0 2px',width:'56'}}>
                  <img src={data.urlM} alt='icon' style={{height:'56px',display:'flex',cursor:'pointer'}} onClick={this.onClick(data.id)}/>
                </TableCell>
                {/* <TableCell>{data.hint}</TableCell> */}
                {/* <TableCell className={classes.cellpointer} onClick={()=> this.openTitle(data)}>
                  <TitleGet store="food_type" lang={lang} docId={data.id}/>
                </TableCell>
                <TableCell numeric>{data.sortno}</TableCell> */}
                <TableCell numeric className={classes.cellpointer} style={{padding:'0 2px',width:'56'}} onClick={this.onClick(data.id)}>
                  {data.cnt}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}
/////////////////////////////////////////////////////////
const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    // marginTop:0
  },
  table: {
    minWidth: 100,
  },
  cellpointer:{
    cursor:'pointer',
    color: '#3443f6',
  },
});

const MyTable = withStyles(styles)(DataTable);
const Menu = withStyles(styles)(MenuTable);

const FoodType = (props) =>
  <FirestoreCollection
    path="food_types"
    sort="sortno:desc"
    //        limit={5}
    render={({ isLoading, data }) => {
      return isLoading
      ? <p>Loding Data.</p>
      // : <p>Loded Data.</p> ;
      : <Menu dataArr={data} onSelect={props.onSelect}/> ;
    }}
  />

class FoodPage extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      lang: props.lang,
      itemType: null,
    }
    this.selectItem = this.selectItem.bind(this);
  }

  selectItem = (itemKey) => {
    // console.log('selectItem:', itemKey);

    this.setState({ itemType: itemKey });
  }

  render() {

    const {
      itemType
    } =  this.state;

    return (
      <div> 
        <div id='menu' className='leftmenu'>
          <FoodType onSelect={this.selectItem}/>
        </div>
        <div id='datas' className='tabledata'>
          {
            itemType ? 
            <FirestoreCollection
            path={'/food_types/' + itemType + '/items'}
            // sort="hint:asc"
            // limit={5}
            render={({ isLoading, data }) =>
              isLoading
              ? <p>Loding Data.</p>
              : <MyTable dataArr={data} lang={this.props.lang}/>
            }
            />
            : null
          }
        </div>
      </div>
    );
  }
}
export default FoodPage;
