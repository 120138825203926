import React, { Component } from 'react';
import { firebase } from "../../firebase";
import { withStyles } from '@material-ui/core/styles';
import ReactLoading from 'react-loading';
import logo from "./search.png";
import Button from '@material-ui/core/Button';

// import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import TextField from '@material-ui/core/TextField';

class FileUpload extends Component {

  constructor(props){
    super(props);
    this.state = {
      uploadStatus: false,
      imageUrl: this.props.imageUrl ? this.props.imageUrl : '',
    }
    this.handleUploadImage = this.handleUploadImage.bind(this);
  }

  handleUploadImage(e) {
    e.preventDefault();

    const handleDone = this.props.handleDone;
    const fileName = this.props.fileName;
    const file = this.uploadInput.files[0];

    if (fileName === '' ){
      console.log('filename error');
      return;
    }
    console.log(fileName);
    const storageRef = firebase.storage.ref();
    const fileRef = storageRef.child(fileName);

    const metadata = {
      cacheControl: 'public,max-age=18000',
      contentType: 'image/png',
    };

    this.setState({
      uploadStatus: true
    });
    fileRef.put(file, metadata).then( (snapshot) => {
      console.log('Uploaded a file:', snapshot);
      snapshot.ref.getDownloadURL().then( (downloadUrl) => {
        console.log('url:', downloadUrl);
        this.setState({
          imageUrl: downloadUrl
        });
        handleDone(downloadUrl);
        this.setState({
          uploadStatus: false
        });
      });
    })
    .catch(error => {
      this.setState({
        uploadStatus: false
      });
    });
  }

  render() {
    const {
      imageUrl,
      uploadStatus
    } =  this.state;

    const { classes, handleClose } = this.props;

    const url = imageUrl? imageUrl : logo;
    return (
      <div>
        <DialogContent>
          {uploadStatus 
              ? <ReactLoading type={'spinningBubbles'} color={'#F00'} height={78} width={78} /> 
              : <img src = {url} alt='' style={{width:'100px',height:'100px'}}/> }            
            <input className='form-control' ref={(ref) => {this.uploadInput = ref;}} type='file' />
            {/* <button className='btn btn-success' type='button' onClick={this.handleUploadImage} ><i className='material-icons right'>send</i>Upload</button> */}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" className={classes.button} onClick={this.handleUploadImage}>
            Upload
          </Button>
          <Button variant="contained" className={classes.button} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>        
      </div>
    );
  }
}

FileUpload.defaultProps = {
  fileName: ''
};

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
});


export default withStyles(styles)(FileUpload);
