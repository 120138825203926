import React from 'react';
import { Link, withRouter } from "react-router-dom";

import { auth } from "../../firebase";
import * as routes from "../../constants/routes";
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


const styles = theme => ({
  root: {
    width: '400px',
    marginTop: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowX: 'auto',
  },
  form: {
    marginLeft: '20px',
    marginRight: '20px'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
  },
  table: {
    minWidth: 400,
  },
  button: {
    margin: theme.spacing(1),
  },
  cellpointer:{
    cursor:'pointer',
    color: '#3443f6',
  }
});


const SignUpPage = ({classes, history }) => 
  <Paper className={classes.root}>
    <div className={classes.form}>
      <h2 align='center'>Sign Up</h2>
      <SignUpForm history={history} classes={classes}/>
      <SignInLink />
    </div>
  </Paper>

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null
};


const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {...INITIAL_STATE};
  }

  onSubmit = (event) => {
    const {
      // username,
      email,
      passwordOne,
    } =  this.state;
    const {
      history
    } = this.props;

    auth.doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        this.setState({...INITIAL_STATE});
        history.push(routes.HOME);
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });
    event.preventDefault();
  }

  updateInput = (e) => {
    this.setState({
      [e.target.id]: e.target.type === 'number' ? Number(e.target.value) : e.target.value
    });
  }

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
    } =  this.state;

    const isInvalid = 
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';
    
    const {classes} = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        <TextField
          id="username"
          label="User Name"
          autoComplete="username"
          className={classes.textField}
          value={username}
          onChange={this.updateInput}
          margin="normal"
        />
        <TextField
          id="email"
          label="Email"
          autoComplete="email"
          className={classes.textField}
          value={email}
          onChange={this.updateInput}
          margin="normal"
        />
        <TextField
          id="passwordOne"
          label="Password"
          className={classes.textField}
          type="password"
          autoComplete="new-password"
          margin="normal"
          value={passwordOne}
          onChange={this.updateInput}
        />
        <TextField
          id="passwordTwo"
          label="Password"
          className={classes.textField}
          type="password"
          autoComplete="new-password"
          margin="normal"
          value={passwordTwo}
          onChange={this.updateInput}
        />
        <br />
        <Button variant='contained' className={styles.button} disabled={isInvalid} type="submit">
            Sign Up
          </Button>
        
        { error && <p>{error.message}</p> }
      </form>
    );
  }
}

const SignUpLink = () =>
  <p>
    Don't have an account?{' '}<Link className="blue-link" to={routes.SIGN_UP}>Sign Up</Link>
  </p>

const SignInLink = () =>
  <p>
    I have an account.{' '}<Link className="blue-link" to={routes.SIGN_IN}>Sign In</Link>
  </p>

const MySignUp = withStyles(styles)(SignUpPage);

export default withRouter(MySignUp);

export {
  SignUpForm,
  SignUpLink,
};