import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FirestoreCollection } from 'react-firestore';

import PhotoSelect from "./PhotoSelect";
import ToolEdit from "./ToolEdit";
import TitleEdit from "./TitleEdit";
import TitleGet from "./TitleGet";
import CheckDlg from "./CheckDlg";
// import { firebase } from "../../firebase";

/////////////////////////////////////////////////////////////////////////////////////////////////////////
class DataTable extends React.Component{
  
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isPhoto: false,
      isTitle: false,
      isDefault: false,
      data: null,
    };
  }

  openModal = (data) => this.setState({isOpen: true, data: data });
  closeModal = () => this.setState({ isOpen: false, data: null });

  openPhoto = (data) => this.setState({isPhoto: true, data: data });
  closePhoto = () => this.setState({ isPhoto: false, data: null });

  openTitle = (data) => this.setState({isTitle: true, data: data });
  closeTitle = () => this.setState({ isTitle: false, data: null });

  openDefault = (data) => this.setState({isDefault: true, data: data });
  closeDefault = () => this.setState({ isDefault: false, data: null });

  render(){

    const { classes, dataArr, lang } =  this.props;

    const photoId = this.state.data
                    ? (this.state.data.id? this.state.data.id:'')
                    :'';
    const photoUrl = this.state.data
                    ? (this.state.data.urlM? this.state.data.urlM:'')
                    :'';

    return(
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{width:'150'}} className={classes.cellpointer} onClick={()=> this.openModal(null)}>
                New Tool</TableCell>
              <TableCell>Photo</TableCell>
              <TableCell>Hint</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Default</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>weight</TableCell>
              <TableCell>width</TableCell>
              <TableCell>length</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataArr.map(data =>
              <TableRow key={data.id}>
                <TableCell component="th" scope="row" className={classes.cellpointer} onClick={() => this.openModal(data)}>
                  {data.id}</TableCell>
                <TableCell style={{padding:'0 2px'}}>{ data.urlM
                  ? <img src={data.urlM} alt='icon' style={{height:'56px',display:'flex',cursor:'pointer'}} onClick={() => this.openPhoto(data)}/>
                  : <button onClick={() => this.openPhoto(data)}> Photo </button> }
                </TableCell>
                <TableCell>{data.hint}</TableCell>
                <TableCell className={classes.cellpointer} onClick={()=> this.openTitle(data)}>
                  <TitleGet store="tool" lang={lang} docId={data.id}/>
                </TableCell>
                <TableCell className={classes.cellpointer} onClick={()=> this.openDefault(data)}>{data.isDefault ? 'Y': '_'}</TableCell>
                <TableCell numeric>{data.size}</TableCell>
                <TableCell numeric>{data.weight}</TableCell>
                <TableCell numeric>{data.width}</TableCell>
                <TableCell numeric>{data.length}</TableCell>
            </TableRow>
          )}
          </TableBody>
        </Table>
        <CheckDlg show={this.state.isDefault}
          dbType={'tools'}
          field={'isDefault'}
          handleClose={this.closeDefault}
          data={this.state.data}
          isUse={this.state.data ? this.state.data.isDefault : false}
        />
        <ToolEdit show={this.state.isOpen}
          handleClose={this.closeModal}
          data={this.state.data}
        />
        <PhotoSelect show={this.state.isPhoto}
          handleClose={this.closePhoto}
          docId={photoId}
          imageUrl={photoUrl}
          storeage={'tool_image'}
          store={'tools'}
        />
        <TitleEdit
          show={this.state.isTitle}
          handleClose={this.closeTitle}
          docId={this.state.data ? this.state.data.id : ''}
          lang={lang}
          store={'tool'}          
        />
      </Paper>
    );
  }
}

DataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  dataArr: PropTypes.array.isRequired,
};

//////////////////////////////////////////////////////////
const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
  cellpointer:{
    cursor:'pointer',
    color: '#3443f6',
  }
});

const MyTable = withStyles(styles)(DataTable);

// class Tool extends React.Component{
  
//   constructor(props) {
//     super(props);
//     this.state = {
//       data:[],
//       page: props.page,
//       rowsPerPage: 10,
//       // endDoc: null,
//     };
//   }
//   componentDidMount(){
//     // this.query();
//   }

//   async query(params){
//     const db = firebase.firestore;
//     const arr = [];
//     const ref = db.collection('tools')
//     // if (this.state.endDoc){
//     //   ref = ref.startAfter(this.state.endDoc)
//     // }

//     const response = await ref
//     // .limit(10)
//     .get()
//     .then((snapshot) => {
//       snapshot.forEach((doc) => {
//         console.log(doc.id, '=>', doc.data());
//         const data = doc.data();
//         data.id = doc.id;
//         arr.push(data);        
//       });
//       this.setState({
//         data: arr,
//         // endDoc: arr.length > 0 ? arr.slice(-1).pop() : null
//       });
//     })
//     .catch((err) => {
//       console.log('Error getting documents', err);
//     });

//     // const response = await db
//     // .collection("tools")
//     // .orderBy("id")
//     // .onSnapshot(snapshot => {
//     //   this.setUsers(snapshot);
//     // });
//   }

//   render() {
//     return (
//         this.state.data.length === 0
//           ? <p>Loding Data.</p>
//           :<MyTable dataArr={this.state.data} lang={this.props.lang}/>
//     );
//   }
// }

const Tool = (props) => {
  return (
    <FirestoreCollection
      path="tools"
      sort="hint:desc"
      //limit={5}
      render={({ isLoading, data }) =>
        isLoading
        ? <p>Loding Data.</p>
        :<MyTable dataArr={data} lang={props.lang}/>
      }
    />
  );
};

export default Tool;
